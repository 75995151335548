import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createClientSegment,
  getAllChannels,
  getAllCitiesWithExistingClients,
  getAllCountriesWithExistingClients,
  getAllSexTypes,
  getBranchOfficesFiltered,
  getBrandsFiltered,
  getClientLevels,
  getClients,
  previewClientSegment,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  InputNumber,
  Spin,
  Switch,
  Upload,
  DatePicker,
  Table,
  Typography,
} from "antd";

import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import AddArticleModal from "../../components/addarticlemodal/AddArticleModal";
import styles from "./NewClientSegment.module.scss";
import moment from "moment";

const NewClientSegment = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [sexTypes, setSexTypes] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [levels, setLevels] = useState<any[]>([]);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);
  const [csvData, setCsvData] = useState<string[]>([]);

  const [clients, setClients] = useState<any[]>([]);
  const [clientsPreview, setClientsPreview] = useState<any[]>([]);
  const [loadingClients, setLoadingClients] = useState(false);
  const [loadingClientsPreview, setLoadingClientsPreview] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [totalSizePreview, setTotalSizePreview] = useState(0);
  const [actualPagePreview, setActualPagePreview] = useState(1);
  const [idNumberFilterValue, setIdNumberFilterValue] = useState<string>("");
  const [levelFilterValue, setLevelFilterValue] = useState<string>("");
  const [actualFilterBirthStartDate, setActualFilterBirthStartDate] =
    useState<any>(null);
  const [actualFilterBirthdateEndDate, setActualFilterBirthdateEndDate] =
    useState<any>(null);
  const [actualFilterRegisterStartDate, setActualFilterRegisterStartDate] =
    useState<any>(null);
  const [actualFilterRegisterEndDate, setActualFilterRegisterEndDate] =
    useState<any>(null);
  const [clientLevels, setClientLevels] = useState<any[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedClients, setSelectedClients] = useState<any[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.WRITE_CLIENTS)) {
      handleFetchCountries();
      handleFetchSexTypes();
      handleFetchCities();
      handleSearchBrands("");
      handleSearchLevels("");
      handleFetchChannels();
    }
  }, []);

  const handleAddArticle = (articlesToAdd: any) => {
    const newArticles: any[] = [];
    articlesToAdd.forEach((articleToAdd: any) => {
      if (
        articlesSelected.find(
          (article: any) => article.id === articleToAdd.id
        ) === undefined
      )
        newArticles.push(articleToAdd);
    });
    setArticlesSelected([...articlesSelected, ...newArticles]);
  };

  const handleRemoveArticle = (articleRemove: any) => {
    const articlesWithoutRemoved = articlesSelected.filter(
      (article) => article.id !== articleRemove.id
    );

    setArticlesSelected(articlesWithoutRemoved);
  };

  const handleFetchCountries: () => any = async () => {
    const response = await getAllCountriesWithExistingClients();

    setCountries(response);
  };

  const handleFetchCities: () => any = async () => {
    const response = await getAllCitiesWithExistingClients();

    setCities(response);
  };

  const handleFetchSexTypes = async () => {
    const response = await getAllSexTypes();
    setSexTypes(response);
  };

  const handleFetchChannels = async () => {
    const response = await getAllChannels();
    setChannels(response);
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result) {
        const content = event.target.result as string;

        const lines = content.split(/\r?\n/);
        const ids = lines.map((line) => line.trim()).filter(Boolean);

        setCsvData(ids);
      }
    };

    reader.onerror = () => {
      console.error("Error al leer el archivo");
    };

    reader.readAsText(file);

    return false;
  };

  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };
  const handleSearchLevels = async (value: string) => {
    const response = await getClientLevels(0, { name: value });
    setLevels(response.clientLevels);
  };

  const getSexTypesOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });
    return options;
  };

  const getChannelOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {`${item.businessUnit?.name} - ${item.type.descriptionEs}`}
        </Select.Option>
      );
    });
    return options;
  };


  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handlePreviewClientSegment = async (page = actualPagePreview) => {
    setLoadingClientsPreview(true);

    const data = form.getFieldsValue();
    const articleList = articlesSelected.map((article) => article.id);
    let response;

    const { birthdate, registrationDate, purchaseCountPeriodRange } = data;
    const birthdayStartDate = birthdate
      ? birthdate[0].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const birthdayEndDate = birthdate
      ? birthdate[1].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const registrationStartDate = registrationDate
      ? registrationDate[0].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const registrationEndDate = registrationDate
      ? registrationDate[1].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const periodStartDate = purchaseCountPeriodRange
      ? purchaseCountPeriodRange[0].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const periodEndDate = purchaseCountPeriodRange
      ? purchaseCountPeriodRange[1].format("YYYY-MM-DDTHH:mm:ss")
      : null;

    response = await previewClientSegment(
      {
        type: "DYNAMIC",
        name: data.name,
        minAge: data.minAge,
        maxAge: data.maxAge,
        description: data.description ?? "",
        sex: data.sex,
        averageTicket: data.averageTicket,
        purchaseChannelId: data.purchaseChannelId,
        purchaseCountPeriod: data.purchaseCountPeriod,
        purchaseCountLastDays: data.purchaseCountLastDays,
        purchaseCountRange: data.purchaseCountRange,
        purchaseCountStartDate: periodStartDate,
        purchaseCountEndDate: periodEndDate,
        totalSpent: data.totalSpent,
        birthdayStartDate: birthdayStartDate,
        birthdayEndDate: birthdayEndDate,
        programRegistrationStartDate: registrationStartDate,
        programRegistrationEndDate: registrationEndDate,
        active: data.active,
        skuId: "", //ids
        articleIds: articleList, //ids
        brandIds: data.brandsIds,
        clientLevelId: data.clientLevelId,
      },
      page
    );

    if (response) {
      console.log(response);
      setClientsPreview(response.clients);
      setTotalSizePreview(response.totalElements);
    } else message.error(t("Common-CreateErrorMessage"));

    setLoadingClientsPreview(false);
  };
  const handleCreateClientSegment = async () => {
    setLoading(true);

    const data = form.getFieldsValue();
    const articleList = articlesSelected.map((article) => article.id);
    let response;

    const { birthdate, registrationDate, purchaseCountPeriodRange } = data;
    const birthdayStartDate = birthdate
      ? birthdate[0].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const birthdayEndDate = birthdate
      ? birthdate[1].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const registrationStartDate = registrationDate
      ? registrationDate[0].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const registrationEndDate = registrationDate
      ? registrationDate[1].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const periodStartDate = purchaseCountPeriodRange
      ? purchaseCountPeriodRange[0].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const periodEndDate = purchaseCountPeriodRange
      ? purchaseCountPeriodRange[1].format("YYYY-MM-DDTHH:mm:ss")
      : null;

    const clientIds = selectedClients.map((client) => client.id);
    if (data.type === "STATIC") {
      response = false;
      response = await createClientSegment({
        type: "STATIC",
        name: data.name,
        description: data.description ?? "",
        clientIds: [...csvData, ...clientIds],
        active: data.active,
      });
    } else {
      response = await createClientSegment({
        type: "DYNAMIC",
        name: data.name,
        minAge: data.minAge,
        maxAge: data.maxAge,
        description: data.description ?? "",
        sex: data.sex,
        averageTicket: data.averageTicket,
        purchaseChannelId: data.purchaseChannelId,
        purchaseCountPeriod: data.purchaseCountPeriod,
        purchaseCountLastDays: data.purchaseCountLastDays,
        purchaseCountRange: data.purchaseCountRange,
        purchaseCountStartDate: periodStartDate,
        purchaseCountEndDate: periodEndDate,
        totalSpent: data.totalSpent,
        birthdayStartDate: birthdayStartDate,
        birthdayEndDate: birthdayEndDate,
        programRegistrationStartDate: registrationStartDate,
        programRegistrationEndDate: registrationEndDate,
        active: data.active,
        skuId: "", //ids
        articleIds: articleList, //ids
        brandIds: data.brandsIds,
        clientLevelId: data.clientLevelId,
      });
    }
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();

      window.location.href = "/promotions/clientsegments";
    } else message.error(t("Common-CreateErrorMessage"));

    setLoading(false);
  };

  const clientsColumns = [
    {
      title: t("Common-Identifier"),
      dataIndex: "idNumber",
    },
    {
      title: t("Common-FidelityId"),
      dataIndex: "fidelity",
    },
  ];

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchClients();
    }
  }, [
    idNumberFilterValue,
    levelFilterValue,
    actualFilterBirthdateEndDate,
    actualFilterBirthStartDate,
    actualFilterRegisterStartDate,
    actualFilterRegisterEndDate,
  ]);

  const handleFetchClients = async (pagination = actualPage) => {
    setLoadingClients(true);
    const response = await getClients(pagination, {
      idNumber: idNumberFilterValue,
      levelId: levelFilterValue,
      birthStartDate: actualFilterBirthStartDate
        ? moment(actualFilterBirthStartDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : "",
      birthEndDate: actualFilterBirthdateEndDate
        ? moment(actualFilterBirthdateEndDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )
        : "",
      registerStartDate: actualFilterRegisterStartDate
        ? moment(actualFilterRegisterStartDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )
        : "",
      registerEndDate: actualFilterRegisterEndDate
        ? moment(actualFilterRegisterEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : "",
    });
    setClients(response.clients);
    setTotalSize(response.totalElements);
    setLoadingClients(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], newSelectedClients: any[]) => {
      setSelectedRowKeys((prevKeys) =>
        Array.from(new Set([...prevKeys, ...newSelectedRowKeys]))
      );
      setSelectedClients((prevClients) => {
        const updatedClients = [...prevClients, ...newSelectedClients];
        return updatedClients.filter(
          (client, index, self) =>
            self.findIndex((c) => c.id === client.id) === index
        );
      });
    },
    onSelect: (record: any, selected: boolean) => {
      if (!selected) {
        setSelectedRowKeys((prevKeys) =>
          prevKeys.filter((key) => key !== record.id)
        );
        setSelectedClients((prevClients) =>
          prevClients.filter((client) => client.id !== record.id)
        );
      }
    },
    onSelectAll: (
      selected: boolean,
      selectedRows: any[],
      changeRows: any[]
    ) => {
      if (selected) {
        setSelectedRowKeys((prevKeys) =>
          Array.from(new Set([...prevKeys, ...changeRows.map((row) => row.id)]))
        );
        setSelectedClients((prevClients) => {
          const updatedClients = [...prevClients, ...changeRows];
          return updatedClients.filter(
            (client, index, self) =>
              self.findIndex((c) => c.id === client.id) === index
          );
        });
      } else {
        setSelectedRowKeys((prevKeys) =>
          prevKeys.filter((key) => !changeRows.find((row) => row.id === key))
        );
        setSelectedClients((prevClients) =>
          prevClients.filter(
            (client) => !changeRows.find((row) => row.id === client.id)
          )
        );
      }
    },
  };

  const handleChangeBirthDateFilter = (value: any) => {
    setActualFilterBirthStartDate(value ? value[0] ?? null : null);
    setActualFilterBirthdateEndDate(value ? value[1] ?? null : null);
  };

  const handleChangeRegisterDateFilter = (value: any) => {
    setActualFilterRegisterStartDate(value ? value[0] ?? null : null);
    setActualFilterRegisterEndDate(value ? value[1] ?? null : null);
  };

  const handlePagination = (page: number) => {
    handleFetchClients(page);
    setActualPage(page);
  };

  const handlePaginationPreview = (page: number) => {
    handlePreviewClientSegment(page);
    setActualPagePreview(page);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewClientSegment")}</h1>
        <Button
          onClick={handleCreateClientSegment}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newSegment"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={handleCreateClientSegment}
            form={form}
            autoComplete="off"
            className={styles.formNew}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item label={t("Common-Description")} name="description">
              <Input placeholder={t("Common-Description")} />
            </Form.Item>
            <Form.Item
              initialValue={true}
              label={t("Common-Active")}
              name="active"
            >
              <Switch defaultChecked={true} />
            </Form.Item>
            <Form.Item
              label={t("Common-Type")}
              name="type"
              rules={[
                {
                  required: true,
                  message: t("Common-Type"),
                },
              ]}
            >
              <Select placeholder={t("Common-Type")} allowClear>
                <Select.Option value="STATIC">Estático</Select.Option>
                <Select.Option value="DYNAMIC">Dinámico</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type
              }
            >
              {({ getFieldValue }) => {
                switch (getFieldValue("type")) {
                  case "DYNAMIC":
                    return (
                      <>
                        <Form.Item
                          className={styles.newItem}
                          label={t("Common-Sex")}
                          name="sex"
                        >
                          <Select placeholder={t("Common-Sex")} allowClear>
                            {getSexTypesOptions(sexTypes)}
                          </Select>
                        </Form.Item>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-AverageTicket")}
                            name="averageTicket"
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-AverageTicket")}
                            />
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-Channel")}
                            name="purchaseChannelId"
                          >
                            <Select
                              mode="multiple"
                              placeholder={t("Common-Channel")}
                              allowClear
                            >
                              {getChannelOptions(channels)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-MinAge")}
                            name="minAge"
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-MinAge")}
                            />
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-MaxAge")}
                            name="maxAge"
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-MaxAge")}
                            />
                          </Form.Item>
                        </div>
                        {/*<div className={styles.formItemsWrapper}>*/}
                        {/*  <Form.Item*/}
                        {/*    label={t("Common-Country")}*/}
                        {/*    name="countryIds"*/}
                        {/*    className={styles.doubleItemLine}*/}
                        {/*  >*/}
                        {/*    <Select*/}
                        {/*      mode="multiple"*/}
                        {/*      allowClear*/}
                        {/*      placeholder={t("Common-SelectOneOrMore")}*/}
                        {/*      // onChange={handleChange}*/}
                        {/*      className={styles.multipleSelect}*/}
                        {/*      filterOption={(input: string, option: any) =>*/}
                        {/*        option.children*/}
                        {/*          .toLowerCase()*/}
                        {/*          .indexOf(input.toLowerCase()) >= 0*/}
                        {/*      }*/}
                        {/*    >*/}
                        {/*      {getCountriesOptions(countries)}*/}
                        {/*    </Select>*/}
                        {/*  </Form.Item>*/}
                        {/*  <Form.Item*/}
                        {/*    label={t("Common-City")}*/}
                        {/*    name="stateIds"*/}
                        {/*    className={styles.doubleItemLine}*/}
                        {/*  >*/}
                        {/*    <Select*/}
                        {/*      mode="multiple"*/}
                        {/*      allowClear*/}
                        {/*      placeholder={t("Common-SelectOneOrMore")}*/}
                        {/*      // onChange={handleChange}*/}
                        {/*      className={styles.multipleSelect}*/}
                        {/*      filterOption={(input: string, option: any) =>*/}
                        {/*        option.children*/}
                        {/*          .toLowerCase()*/}
                        {/*          .indexOf(input.toLowerCase()) >= 0*/}
                        {/*      }*/}
                        {/*    >*/}
                        {/*      {getCountriesOptions(cities)}*/}
                        {/*    </Select>*/}
                        {/*  </Form.Item>*/}
                        {/*</div>*/}
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            label={t("Common-Brands")}
                            name="brandsIds"
                            className={styles.doubleItemLine}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              placeholder={t("Common-SearchByName")}
                              onSearch={handleSearchBrands}
                              filterOption={(input: string, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {getOptions(brands)}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-Level")}
                            name="clientLevelId"
                          >
                            <Select
                              allowClear
                              showSearch
                              placeholder={t("Common-SearchByName")}
                              onSearch={handleSearchLevels}
                              filterOption={(input: string, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {getOptions(levels)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            label={t("Common-Period")}
                            name="purchaseCountPeriodRange"
                            className={styles.doubleItemLine}
                          >
                            <DatePicker.RangePicker
                              placeholder={[t("Common-Start"), t("Common-End")]}
                            ></DatePicker.RangePicker>
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-PurchaseCount")}
                            name="purchaseCountPeriod"
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-PurchaseCount")}
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-XDays")}
                            name="purchaseCountRange"
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-XDays")}
                            />
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-PurchaseCountDays")}
                            name="purchaseCountLastDays"
                          >
                            <Input
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-PurchaseCountDays")}
                            />
                          </Form.Item>
                        </div>

                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-TotalSpent")}
                            name="totalSpent"
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-TotalSpent")}
                            />
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-BirthdayBetween")}
                            name="birthdate"
                          >
                            <DatePicker.RangePicker
                              placeholder={[t("Common-Start"), t("Common-End")]}
                            ></DatePicker.RangePicker>
                          </Form.Item>
                        </div>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-ProgramRegistrationDateBetween")}
                            name="registrationDate"
                          >
                            <DatePicker.RangePicker
                              placeholder={[t("Common-Start"), t("Common-End")]}
                            ></DatePicker.RangePicker>
                          </Form.Item>
                        </div>
                        <AddArticleModal
                          selectedArticles={articlesSelected}
                          onAddArticle={handleAddArticle}
                          onRemoveArticle={handleRemoveArticle}
                        />
                        <div className={styles.tableWrapper}>
                          <div className={styles.headerTableWrapper}>
                            <h1 className={styles.subTitle}>
                              {`${t("Common-Preview")}`}
                            </h1>
                            <Button
                              onClick={() => handlePreviewClientSegment(1)}
                            >
                              {t("Common-Load")}
                            </Button>
                          </div>
                          {totalSizePreview ? (
                            <>
                              <Typography.Text>
                                {totalSizePreview
                                  ? `${totalSizePreview} ${t("Common-Clients")}`
                                  : ""}
                              </Typography.Text>
                            </>
                          ) : (
                            ""
                          )}
                          <Table
                            size="small"
                            className={styles.table}
                            loading={loadingClientsPreview}
                            rowKey={(record) => record.id}
                            columns={clientsColumns}
                            dataSource={clientsPreview}
                            pagination={{
                              total: totalSizePreview,
                              showSizeChanger: false,
                              onChange: handlePaginationPreview,
                            }}
                          />
                        </div>
                      </>
                    );
                  case "STATIC":
                    return (
                      <>
                        <Form.Item label={"Listado de clientes"} name="csv">
                          <Upload
                            beforeUpload={(file) => {
                              handleFileUpload(file);
                              return false;
                            }}
                          >
                            <Button icon={<UploadOutlined />}>
                              Click para subir archivo
                            </Button>
                          </Upload>
                        </Form.Item>
                        <div className={styles.tableWrapper}>
                          <div className={styles.headerTableWrapper}>
                            <div className={styles.filtersWrapper}>
                              <div className={styles.filterWrapper}>
                                <Input
                                  placeholder={t("Common-Identifier")}
                                  className={styles.filterInput}
                                  onChange={(e) => {
                                    setIdNumberFilterValue(e.target.value);
                                    setActualPage(1);
                                  }}
                                  value={idNumberFilterValue}
                                />
                              </div>
                              <div className={styles.filterWrapper}>
                                <Select
                                  className={styles.filterSelect}
                                  showSearch
                                  onSelect={(_, option) => {
                                    if (option)
                                      setLevelFilterValue(option.value);
                                  }}
                                  allowClear
                                  filterOption={(input: string, option: any) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onClear={() => setLevelFilterValue("")}
                                  onSearch={handleSearchLevels}
                                  placeholder={t("Common-ClientLevel")}
                                >
                                  {getOptions(clientLevels)}
                                </Select>
                              </div>
                            </div>
                            <div className={styles.filtersWrapper}>
                              <div className={styles.filterWrapper}>
                                <DatePicker.RangePicker
                                  className={styles.dateSelector}
                                  format="YYYY-MM-DD"
                                  allowEmpty={[true, true]}
                                  onChange={handleChangeBirthDateFilter}
                                  onCalendarChange={handleChangeBirthDateFilter}
                                  placeholder={[
                                    t("Common-StartDateBirthdate"),
                                    t("Common-EndDateBirthdate"),
                                  ]}
                                />
                              </div>
                              <div className={styles.filterWrapper}>
                                <DatePicker.RangePicker
                                  className={styles.dateSelector}
                                  format="YYYY-MM-DD"
                                  allowEmpty={[true, true]}
                                  onChange={handleChangeRegisterDateFilter}
                                  onCalendarChange={
                                    handleChangeRegisterDateFilter
                                  }
                                  placeholder={[
                                    t("Common-StartDateRegister"),
                                    t("Common-EndDateRegister"),
                                  ]}
                                />
                              </div>
                            </div>
                          </div>

                          <Table
                            size="small"
                            className={styles.table}
                            loading={loadingClients}
                            rowKey={(record) => record.id}
                            columns={clientsColumns}
                            dataSource={clients}
                            pagination={{
                              total: totalSize,
                              showSizeChanger: false,
                              onChange: handlePagination,
                            }}
                            rowSelection={{
                              ...rowSelection,
                            }}
                          />
                        </div>
                        <div className={styles.tableWrapper}>
                          <div className={styles.headerTableWrapper}>
                            <h1 className={styles.subTitle}>
                              {`${t("Common-Preview")}`}
                            </h1>
                          </div>
                          {selectedClients.length  ? (
                            <>
                              <Typography.Text>
                                {selectedClients.length > 0
                                  ? `${selectedClients.length} ${t("Common-Clients")}`
                                  : ""}
                              </Typography.Text>
                            </>
                          ) : (
                            ""
                          )}
                          <Table
                            size="small"
                            className={styles.table}
                            rowKey={(record) => record.id}
                            columns={clientsColumns}
                            dataSource={selectedClients}
                            pagination={{
                              total: selectedClients.length,
                              showSizeChanger: false,
                              onChange: handlePaginationPreview,
                            }}
                          />
                        </div>
                      </>
                    );
                }
              }}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewClientSegment;
