import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getPromotions,
  deletePromotion,
  cancelPromotion,
  schedulePromotion,
  getCategoriesFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Form,
  Button,
  message,
  Modal,
  Spin,
  DatePicker,
  Select,
  Input,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
  CalendarOutlined,
  LoadingOutlined,
  CloseOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import styles from "./PromotionsMockup.module.scss";
import { useNavigate } from "react-router-dom";

const PromotionsMockUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);

  const [scheduledForm] = Form.useForm();

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PROMOTIONS)) {
      handleFetchPromotions();
    }
  }, []);

  const handleFetchPromotions = async (pagination = actualPage) => {
    const response = await getPromotions(pagination);
    const allPromotions = response.promotions;
    const promotionsWithKey: any[] = [];

    allPromotions.forEach((promotion: any) => {
      promotion.key = promotion.id;
      promotionsWithKey.push(promotion);
    });
    setData(promotionsWithKey);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (itemId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(itemId);
      },
    });
  };

  const showCancelConfirm = (itemId: number) => {
    Modal.confirm({
      title: t("Common-CancelPromotionConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleCancel(itemId);
      },
    });
  };

  const handleCancel = async (promotionId: number) => {
    setLoading(true);
    const deleteStatus = await cancelPromotion(promotionId);
    if (!deleteStatus) message.error(t("Common-ErrorMessage"));
    else message.success(t("Common-SuccessMessage"));
    await handleFetchPromotions();
    setLoading(false);
  };

  const handleDelete = async (promotionId: number) => {
    setLoading(true);
    const deleteStatus = await deletePromotion(promotionId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchPromotions();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchPromotions(page);
    setActualPage(page);
  };

  const handleScheduled = async () => {
    const data = scheduledForm.getFieldsValue();
    const { id } = scheduledForm.getFieldsValue();
    const startDate = data.startDate.format("YYYY-MM-DDTHH:mm:ss");
    const endDate = data.endDate?.format("YYYY-MM-DDTHH:mm:ss");

    const response = await schedulePromotion(id, startDate, endDate);
    if (response) {
      Modal.destroyAll();
      message.success(t("Common-ProcessSuccessMessage"));
      await handleFetchPromotions(actualPage);
    } else message.error(t("Common-ErrorMessage"));
  };

  const showScheduledModal = (promotion: any) => {
    Modal.info({
      title: t("Common-Schedule"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="receivedOrder"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={scheduledForm}
          preserve={false}
        >
          <Form.Item hidden initialValue={promotion.id} name="id" />
          <Form.Item
            label={t("Common-StartDate")}
            name="startDate"
            rules={[
              {
                required: true,
                message: t("Common-StartDate"),
              },
            ]}
          >
            <DatePicker
              className={styles.dateSelector}
              showTime
              placeholder={t("Common-SelectDate")}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item label={t("Common-EndDate")} name="endDate">
            <DatePicker
              className={styles.dateSelector}
              showTime
              placeholder={t("Common-SelectDate")}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
              onClick={handleScheduled}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Save")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
    },
    {
      title: t("Common-Origin"),
    },
    {
      title: t("Common-Status"),
    },
    {
      title: t("Common-StartDate"),
      dataIndex: "startDate",
      key: "startDate",
      render: (date: any) =>
        date
          ? moment(date, "YYYY-MM-DDHH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
          : "",
    },
    {
      title: t("Common-EndDate"),
      dataIndex: "endDate",
      key: "endDate",
      render: (date: any) =>
        date
          ? moment(date, "YYYY-MM-DDHH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
          : "",
    },
    {
      title: t("Common-Type"),
      dataIndex: "type",
      key: "type",
      render: (type: any) => type?.description_es,
    },
    {
      title: t("Common-Analysis"),
    },
    {
      title: t("Common-Suggestion"),
    },
    {
      title: t("Common-ExpectedResult"),
    },
    {
      title: t("Common-ObtainedResult"),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              {hasPermission(privilegesEnum.WRITE_PROMOTIONS) &&
                record.status.value === "CREATED" && (
                  <Menu.Item
                    key="calendar"
                    onClick={() => {
                      showScheduledModal(record);
                    }}
                    icon={<CalendarOutlined />}
                  >
                    {t("Common-Schedule")}
                  </Menu.Item>
                )}
              {hasPermission(privilegesEnum.WRITE_PROMOTIONS) &&
                (record.status?.value === "CREATED" ||
                  record.status?.value === "SCHEDULED") && (
                  <Menu.Item
                    key="cancel"
                    onClick={() => {
                      showCancelConfirm(record.id);
                    }}
                    icon={<CloseOutlined />}
                  >
                    {t("Common-Cancel")}
                  </Menu.Item>
                )}

              {hasPermission(privilegesEnum.WRITE_PROMOTIONS) ? (
                <Menu.Item
                  key="edit"
                  onClick={() => {
                    window.location.href = `/promotions/${record.id}/view`;
                  }}
                  icon={<EditOutlined />}
                >
                  {t("Common-Edit")}
                </Menu.Item>
              ) : (
                <Menu.Item
                  key="view"
                  onClick={() => {
                    window.location.href = `/promotions/${record.id}/view`;
                  }}
                  icon={<EyeOutlined />}
                >
                  {t("Common-View")}
                </Menu.Item>
              )}

              {hasPermission(privilegesEnum.DELETE_PROMOTIONS) && (
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    showDeleteConfirm(record.key);
                  }}
                  icon={<DeleteOutlined />}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.actionButtonMore}
          >
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Promotions")}</h1>
        {hasPermission(privilegesEnum.WRITE_PROMOTIONS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => navigate("/promotions/calendar")}
              icon={<CalendarOutlined />}
            ></Button>
            <Button
              href="/promotions/create"
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button
              // onClick={handleCancelButton}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
            <Button className={styles.importButton} type="primary">
              {t("Common-DownloadCsv")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    className={styles.inputFilter}
                    placeholder={"Nombre"}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    showSearch
                    allowClear
                    placeholder={t("Common-Status")}
                    className={styles.filterSelect}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                  >
                    <Select.Option value="active">Activa</Select.Option>
                    <Select.Option value="scheduled">Agendada</Select.Option>
                    <Select.Option value="finishs">Finalizada</Select.Option>
                    <Select.Option value="active">Revision</Select.Option>
                  </Select>
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    showSearch
                    allowClear
                    placeholder={t("Common-Type")}
                    className={styles.filterSelect}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                  >
                    <Select.Option value="active">Manual</Select.Option>
                    <Select.Option value="scheduled">Sugerida</Select.Option>
                  </Select>
                </div>
              </div>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <DatePicker.RangePicker
                    className={styles.dateSelectorFilter}
                    format="YYYY-MM-DD"
                    allowEmpty={[true, true]}
                    placeholder={[t("Common-Start"), t("Common-End")]}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    allowClear
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    className={styles.filterSelect}
                    onSearch={handleSearchCategories}
                    placeholder={t("Common-SearchByCategory")}
                  >
                    {getOptionsCategories(categories)}
                  </Select>
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    showSearch
                    allowClear
                    placeholder={t("Common-Origin")}
                    className={styles.filterSelect}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                  >
                    <Select.Option value="active">Manual</Select.Option>
                    <Select.Option value="scheduled">Sugerida</Select.Option>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PromotionsMockUp;
