import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Table,
  message,
  Modal,
  Select,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  deleteSupplierPaymentMethod,
  editSupplierPaymentMethod,
  getAcceptedPaymentMethodsTypes,
  getSupplierPaymentMethods,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import styles from "./SupplierPaymentMethods.module.scss";

const SupplierPaymentMethods = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [types, setTypes] = useState<any[]>([]);

  const [editForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_SUPPLIER_PAYMENT_METHODS)) {
      handleFetchSupplierPaymentMethods();
      handleFetchAcceptedPaymentMethodsTypes();
    }
  }, []);

  const handleFetchAcceptedPaymentMethodsTypes = async () => {
    const response = await getAcceptedPaymentMethodsTypes();
    setTypes(response);
  };

  const getAcceptedPaymentMethodsTypesOptions = () => {
    const options: any[] = [];

    types.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };

  const handleFetchSupplierPaymentMethods = async (pagination = actualPage) => {
    const response = await getSupplierPaymentMethods(pagination);
    const allSupplierPaymentMethods = response.supplierPaymentMethods;
    const supplierPaymentMethodsWithKey: any[] = [];

    allSupplierPaymentMethods.forEach((supplierPaymentMethod: any) => {
      supplierPaymentMethodsWithKey.push({
        ...supplierPaymentMethod,
        key: supplierPaymentMethod.id,
      });
    });

    setData(supplierPaymentMethodsWithKey);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchSupplierPaymentMethods(page);
    setActualPage(page);
  };

  const showDeleteConfirm = (supplierPaymentMethodId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSupplierPaymentMethod(supplierPaymentMethodId);
      },
    });
  };

  const handleDeleteSupplierPaymentMethod = async (
    supplierPaymentMethodId: number
  ) => {
    setLoading(true);
    const deleteStatus = await deleteSupplierPaymentMethod(
      supplierPaymentMethodId
    );
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchSupplierPaymentMethods();
    setLoading(false);
  };

  const handleEditSupplierPaymentMethod = async () => {
    setLoading(true);

    const id = editForm.getFieldValue("id");
    const name = editForm.getFieldValue("name");
    const type = editForm.getFieldValue("type");
    const paymentDeadlineDays = editForm.getFieldValue("paymentDeadlineDays");
    let response = await editSupplierPaymentMethod(
      id,
      name,
      paymentDeadlineDays,
      type
    );

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchSupplierPaymentMethods();
    setLoading(false);
  };

  function showEditModal(supplierPaymentMethod: any) {
    Modal.info({
      title: t("Common-Suppliers-Payment-Methods"),
      closable: true,
      maskClosable: true,
      okText: t("Common-Save"),
      icon: null,
      okButtonProps: { style: { display: "none" } },
      content: (
        <>
          <Form
            name="editForm"
            layout="vertical"
            initialValues={{ remember: true }}
            form={editForm}
            autoComplete="off"
            preserve={false}
          >
            <Form.Item
              hidden
              initialValue={supplierPaymentMethod.id}
              name="id"
            />
            <Form.Item
              initialValue={supplierPaymentMethod.name}
              label={t("Common-Name")}
              name="name"
              className={styles.editInfoItem}
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>

            <Form.Item
              initialValue={supplierPaymentMethod.paymentDeadlineDays}
              label={t("NewSupplierPaymentMethod-PaymentDeadline")}
              name="paymentDeadlineDays"
              className={styles.editInfoItem}
              rules={[
                {
                  required: true,
                  message: t("NewSupplierPaymentMethod-PaymentDeadlineError"),
                },
              ]}
            >
              <InputNumber
                placeholder={t("NewSupplierPaymentMethod-PaymentDeadline")}
                addonAfter={t("Common-Days")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              initialValue={supplierPaymentMethod?.type?.id}
              label={t("Common-Type")}
              name="type"
              className={styles.editInfoItem}
            >
              <Select
                defaultValue={supplierPaymentMethod?.type?.id}
                className={styles.type}
                allowClear
                style={{ width: "100%" }}
                placeholder=""
              >
                {getAcceptedPaymentMethodsTypesOptions()}
              </Select>
            </Form.Item>
          </Form>
          <Button
            className={styles.addButton}
            type="primary"
            onClick={handleEditSupplierPaymentMethod}
          >
            {t("Common-Save")}
          </Button>
        </>
      ),
    });
  }

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-Type"),
      dataIndex: "type",
      key: "type",
      render: (type: any) => type?.descriptionEs,
    },
    {
      title: t("Suppliers-Payment-Methods-deadlines"),
      dataIndex: "paymentDeadlineDays",
      key: "paymentDeadlineDays",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              {hasPermission(privilegesEnum.WRITE_SUPPLIER_PAYMENT_METHODS) && (
                <Menu.Item
                  key="edit"
                  icon={<EditOutlined />}
                  onClick={() => {
                    showEditModal(record);
                  }}
                >
                  {t("Common-Edit")}
                </Menu.Item>
              )}
              {hasPermission(
                privilegesEnum.DELETE_SUPPLIER_PAYMENT_METHODS
              ) && (
                <Menu.Item
                  key="delete"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    showDeleteConfirm(record.key);
                  }}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button className={styles.actionButtonMore}>
            <MoreOutlined className={styles.actionIconMore} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          {t("Common-Suppliers-Payment-Methods")}
        </h1>
        {hasPermission(privilegesEnum.WRITE_SUPPLIER_PAYMENT_METHODS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => {
                navigate("/admin/suppliers/payment/create");
              }}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_SUPPLIER_PAYMENT_METHODS) && (
          <div className={styles.tableSupplierPaymentMethodsWrapper}>
            <Table
              size="small"
              className={styles.tableSupplierPaymentMethods}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SupplierPaymentMethods;
