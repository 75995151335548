import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getTicketById,
  getAllAcceptedPaymentMethods,
  getAllTicketDocumentTypes,
  getCurrencies,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Form, Select, Input, Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewTicket.module.scss";
import { useParams } from "react-router-dom";
import NotFound from "../notfound/NotFound";

const ViewTicket = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState<any>();
  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState<any[]>(
    []
  );
  const [ticketDocumentTypes, setTicketDocumentTypes] = useState<any[]>([]);
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [channelOptions, setChannelOptions] = useState<any[]>([]);

  const [newTicketForm] = Form.useForm();

  const canEdit = false; //hasPermission(privilegesEnum.WRITE_TICKETS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_TICKETS)) {
      handleFetchTicket();
      handleFetchAcceptedPaymentMethods();
      handleFetchTicketDocumentTypes();
      handleFetchCurrencies();
    }
  }, []);

  const handleFetchAcceptedPaymentMethods = async () => {
    const response = await getAllAcceptedPaymentMethods();
    setAcceptedPaymentMethods(response);
  };

  const handleFetchCurrencies = async () => {
    const response = await getCurrencies();
    setCurrencies(response);
  };

  const handleFetchTicketDocumentTypes = async () => {
    const response = await getAllTicketDocumentTypes();
    setTicketDocumentTypes(response);
  };

  const handleFetchTicket = async () => {
    setLoading(true);

    let response = await getTicketById(id!);
    setTicket(response);
    setChannelOptions(response?.channel?.businessUnit?.channels);
    setLoading(false);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getTicketDocumentTypesOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });
    return options;
  };

  const getChannelOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.type.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };

  const skusColumns = [
    {
      title: t("Common-Name"),
      key: "sku",
      render: (data: any) => data.sku.name,
    },
    {
      title: t("Common-UnitPrice"),
      dataIndex: "unitPrice",
      key: "unitPrice",
    },
    {
      title: t("Common-Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  const promotionsColumns = [
    {
      title: t("Common-Name"),
      key: "name",
      render: (data: any) => (
        <a href={`/promotions/${data.id}/view`}>{data.name}</a>
      ),
    },
    {
      title: t("Common-Type"),
      key: "type",
      render: (data: any) => data.type.description_es,
    },
    {
      title: t("Common-DistributeLines"),
      key: "distributeLines",
      render: (data: any) => (data.distributeLines ? "Si" : "No"),
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !ticket ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Ticket")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.formWrapper}>
          <Form
            name="newTicket"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={newTicketForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Code")}
                className={styles.tripleItemLine}
                initialValue={ticket?.code}
                name="code"
                rules={[
                  {
                    required: true,
                    message: t("Common-Code"),
                  },
                ]}
              >
                <Input
                  type="number"
                  disabled={!canEdit}
                  placeholder={t("Common-Code")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-ClientRut")}
                className={styles.tripleItemLine}
                name="clientRut"
                initialValue={ticket?.clientRut}
                rules={[
                  {
                    required: true,
                    message: t("Common-ClientRut"),
                  },
                ]}
              >
                <Input
                  type="number"
                  disabled={!canEdit}
                  placeholder={t("Common-ClientRut")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-Date")}
                className={styles.tripleItemLine}
                name="date"
                initialValue={ticket?.date}
                rules={[
                  {
                    required: true,
                    message: t("Common-Date"),
                  },
                ]}
              >
                <Input
                  disabled={!canEdit}
                  placeholder={t("Common-ClientRut")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-SubTotal")}
                className={styles.tripleItemLine}
                name="subtotal"
                initialValue={ticket?.subtotal}
                rules={[
                  {
                    required: true,
                    message: t("Common-SubTotal"),
                  },
                ]}
              >
                <Input
                  type="number"
                  disabled={!canEdit}
                  placeholder={t("Common-SubTotal")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-Iva")}
                className={styles.tripleItemLine}
                initialValue={ticket?.iva}
                name="iva"
                rules={[
                  {
                    required: true,
                    message: t("Common-Iva"),
                  },
                ]}
              >
                <Input
                  type="number"
                  disabled={!canEdit}
                  placeholder={t("Common-Iva")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-Total")}
                className={styles.tripleItemLine}
                name="total"
                initialValue={ticket?.total}
                rules={[
                  {
                    required: true,
                    message: t("Common-Total"),
                  },
                ]}
              >
                <Input
                  type="number"
                  disabled={!canEdit}
                  placeholder={t("Common-Total")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Currency")}
                className={styles.tripleItemLine}
                name="currencyId"
                initialValue={ticket?.currency.name}
                rules={[
                  {
                    required: true,
                    message: t("Common-Currency"),
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={t("Common-Currency")}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={ticket?.currency?.name}
                  disabled={!canEdit}
                >
                  {getOptions(currencies)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-AccumulatePoints")}
                className={styles.tripleItemLine}
                name="accumulatePoints"
                initialValue={ticket?.accumulatePoints}
                rules={[
                  {
                    required: true,
                    message: t("Common-AccumulatePoints"),
                  },
                ]}
              >
                <Input
                  type="number"
                  disabled={!canEdit}
                  placeholder={t("Common-AccumulatePoints")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-ConsumedPoints")}
                className={styles.tripleItemLine}
                name="consumedPoints"
                initialValue={ticket?.consumedPoints}
                rules={[
                  {
                    required: true,
                    message: t("Common-ConsumedPoints"),
                  },
                ]}
              >
                <Input
                  type="number"
                  disabled={!canEdit}
                  placeholder={t("Common-ConsumedPoints")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Channel")}
                className={styles.tripleItemLine}
                name="channelId"
                initialValue={ticket?.channel.type.descriptionEs}
                rules={[
                  {
                    required: true,
                    message: t("Common-Channel"),
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!canEdit}
                >
                  {getChannelOptions(channelOptions)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-SalePoint")}
                className={styles.tripleItemLine}
                name="salePoint.name"
                rules={[
                  {
                    required: true,
                    message: t("Common-SalePoint"),
                  },
                ]}
                initialValue={ticket?.salePoint.name}
              >
                <Input
                  disabled={!canEdit}
                  placeholder={t("Common-SalePoint")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-BranchOffice")}
                className={styles.tripleItemLine}
                name="salePoint.branchOffice.name"
                rules={[
                  {
                    required: true,
                    message: t("Common-BranchOffice"),
                  },
                ]}
                initialValue={ticket?.salePoint.branchOffice.name}
              >
                <Input
                  disabled={!canEdit}
                  placeholder={t("Common-BranchOffice")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-FidelityId")}
                className={styles.tripleItemLine}
                name="fidelity"
                initialValue={ticket?.fidelity}
              >
                <Input
                  disabled={!canEdit}
                  placeholder={t("Common-FidelityId")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-DocumentType")}
                className={styles.tripleItemLine}
                name="documentType"
                initialValue={ticket.documentType.description_es}
                rules={[
                  {
                    required: true,
                    message: t("Common-DocumentType"),
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={t("Common-DocumentType")}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={ticket.documentType.description_es}
                  disabled={!canEdit}
                >
                  {getTicketDocumentTypesOptions(ticketDocumentTypes)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-PaymentMethods")}
                className={styles.tripleItemLine}
                name="acceptedPaymentMethodIds"
                initialValue={ticket?.acceptedPaymentMethods.map(
                  (paymentMethod: any) => paymentMethod.id
                )}
                rules={[
                  {
                    required: true,
                    message: t("Common-PaymentMethods"),
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={t("Common-PaymentMethods")}
                  mode="multiple"
                  defaultValue={ticket?.acceptedPaymentMethods.map(
                    (paymentMethod: any) => paymentMethod.id
                  )}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!canEdit}
                >
                  {getOptions(acceptedPaymentMethods)}
                </Select>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{t("Common-Skus")}</h1>
          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={skusColumns}
            dataSource={ticket?.skusWithUnitPrice}
            rowKey={(record) => record.id}
            pagination={false}
          />
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{t("Common-Promotions")}</h1>
          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={promotionsColumns}
            dataSource={ticket?.promotions}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewTicket;
