import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getOrganizations } from "./api/Services";
import { Organization } from "./models/Models";

import AcceptedPaymentMethods from "./pages/acceptedpaymentmethods/AcceptedPaymentMethods";
import ArticleGroups from "./pages/articlegroups/ArticleGroups";
import Articles from "./pages/articles/Articles";
import Attributes from "./pages/attributes/Attributes";
import BranchOffices from "./pages/branchoffices/BranchOffices";
import Brands from "./pages/brands/Brands";
import BusinessRules from "./pages/businessrules/BusinessRules";
import CalendarPricings from "./pages/calendarpricings/CalendarPricings";
import CalendarPromotions from "./pages/calendarpromotions/CalendarPromotions";
import Catalogue from "./pages/repositionorders/catalogue/Catalogue";
import CataloguePurchaseOrder from "./pages/purchaseorders/catalogue/Catalogue";
import Categories from "./pages/categories/Categories";
import ChangePassword from "./pages/changepassword/ChangePassword";
import ChatIa from "./pages/chatia/ChatIa";
import ClientLevels from "./pages/clientlevels/ClientLevels";
import Clients from "./pages/clients/Clients";
import ClientSegments from "./pages/clientsegments/ClientSegments";
import Clusters from "./pages/clusters/Clusters";
import Colours from "./pages/colours/Colours";
import Curves from "./pages/curves/Curves";
import Deposits from "./pages/deposits/Deposits";
import ForgotPassword from "./pages/forgotpassword/ForgotPassword";
import Goals from "./pages/goals/Goals";
import Home from "./pages/home/Home";
import Layout from "./components/laoyut/Layout";
import Login from "./pages/login/Login";
import NewAcceptedPaymentMethods from "./pages/acceptedpaymentmethods/NewAcceptedPaymentMethods/NewAcceptedPaymentMethods";
import NewArticle from "./pages/newarticle/NewArticle";
import NewArticleGroup from "./pages/articlegroups/newarticlegroup/NewArticleGroup";
import NewAttribute from "./pages/attributes/newattribute/NewAttribute";
import NewBranchOffice from "./pages/newbranchoffice/NewBranchOffice";
import NewBrand from "./pages/brands/newbrand/NewBrand";
import NewCategory from "./pages/categories/newcategory/NewCategory";
import NewClient from "./pages/clients/newclient/NewClient";
import NewClientLevel from "./pages/clientlevels/newclientlevel/NewClientLevel";
import NewClientSegment from "./pages/newclientsegment/NewClientSegment";
import NewCluster from "./pages/clusters/newcluster/NewCluster";
import NewColour from "./pages/newcolours/NewColour";
import NewCommercialDates from "./pages/parameters/commercial-dates/NewCommercialDate";
import NewCurve from "./pages/curves/newcurve/NewCurve";
import NewDeposit from "./pages/deposits/newdeposit/NewDeposit";
import NewPricing from "./pages/newpricing/NewPricing";
import NewPromotion from "./pages/newpromotion/NewPromotion";
import NewPurchaseOrder2 from "./pages/newpurchaseorder2/NewPurchaseOrder2";
import NewRepositionOrder from "./pages/newrepositionorder/NewRepositionOrder";
import NewReward from "./pages/fidelity/rewards/newreward/NewReward";
import NewRewardType from "./pages/fidelity/rewardtype/newrewardtype/NewRewardType";
import NewSeason from "./pages/parameters/seasons/NewSeason";
import NewSupplierPaymentMethod from "./pages/supplierpaymentmethods/newsupplierpaymentmethod/NewSupplierPaymentMethod";
import NewSupplier from "./pages/newsupplier/NewSupplier";
import NewUser from "./pages/users/newuser/NewUser";
import NewVehicle from "./pages/vehicles/newvehicle/NewVehicle";
import NotFound from "./pages/notfound/NotFound";
import Parameters from "./pages/parameters/Parameters";
import Pricings from "./pages/pricings/Pricings";
import PricingTypes from "./pages/pricingtypes/PricingTypes";
import PromotionsMockUp from "./pages/promotions/PromotionsMockup";
import PromotionTypes from "./pages/promotiontypes/PromotionTypes";
import PurchaseOrderDashboard from "./pages/purchaseorders/dashboard/PurchaseOrderDashboard";
import PurchaseOrders from "./pages/purchaseorders/PurchaseOrders";
import RecommendationsMockup from "./pages/fidelity/recomendations/RecommendationsMockup";
import RepositionOrderDashboard from "./pages/repositionorders/dashboard/RepositionOrderDashboard";
import RepositionOrders from "./pages/repositionorders/RepositionOrders";
import RequestDetail from "./pages/repositionorders/requests/RequestDetail";
import Requests from "./pages/repositionorders/requests/Requests";
import Rewards from "./pages/fidelity/rewards/Rewards";
import RewardTypes from "./pages/fidelity/rewardtype/RewardTypes";
import Signup from "./pages/signup/Signup";
import StockBreaks from "./pages/repositionorders/stockbreaks/StockBreaks";
import StockBreaksPurchaseOrders from "./pages/purchaseorders/stockbreaks/StockBreaks";
import StockMovements from "./pages/stockmovements/StockMovements";
import Suppliers from "./pages/suppliers/Suppliers";
import Tickets from "./pages/tickets/Tickets";
import TicketsOld from "./pages/tickets/TicketsOld";
import UnitsMockup from "./pages/fidelity/units/UnitsMockup";
import UserRoles from "./pages/UserRoles/UserRoles";
import ValidateEmail from "./pages/validateemail/ValidateEmail";
import Vehicles from "./pages/vehicles/Vehicles";
import ViewAcceptedPaymentMethod from "./pages/acceptedpaymentmethods/viewacceptedpaymentmethod/ViewAcceptedPaymentMethod";
import ViewArticle from "./pages/viewarticle/ViewArticle";
import ViewArticleGroup from "./pages/articlegroups/viewarticlegroup/ViewArticleGroup";
import ViewBranchOffice from "./pages/viewbranchoffice/ViewBranchOffice";
import ViewBusinessUnit from "./pages/viewbusinessunit/ViewBusinessUnit";
import ViewCategory from "./pages/categories/viewcategory/ViewCategory";
import ViewClient from "./pages/viewclient/ViewClient";
import ViewClientLevel from "./pages/clientlevels/viewclientlevel/ViewClientLevel";
import ViewClientSegment from "./pages/viewclientsegment/ViewClientSegment";
import ViewCluster from "./pages/clusters/viewcluster/ViewCluster";
import ViewCommercialDate from "./pages/parameters/commercial-dates/viewcommercialdate/ViewCommercialDate";
import ViewCurve from "./pages/curves/viewcurve/ViewCurve";
import ViewDeposit from "./pages/viewdeposit/ViewDeposit";
import ViewPricing from "./pages/viewpricing/ViewPricing";
import ViewPromotion from "./pages/viewpromotion/ViewPromotion";
import ViewPurchaseOrder from "./pages/viewpurchaseorder/ViewPurchaseOrder";
import ViewRepositionOrder from "./pages/viewrepositionorder/ViewRepositionOrder";
import ViewReward from "./pages/fidelity/rewards/viewreward/ViewReward";
import ViewRewardType from "./pages/fidelity/rewardtype/viewrewardtype/ViewRewardType";
import ViewSeason from "./pages/parameters/seasons/viewseason/ViewSeason";
import ViewSupplier from "./pages/viewsupplier/ViewSupplier";
import ViewTicket from "./pages/viewticket/ViewTicket";
import ViewVehicle from "./pages/vehicles/viewvehicle/ViewVehicle";

import { hasPermission, privilegesEnum } from "./helpers/PermissionHelper";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./App.css";
import NewStockMovement from "./pages/stockmovements/newstockmovement/NewStockMovement";

function App() {
  const [subdomain, setSubDomain] = useState("");
  const [validSubdomains, setValidSubdomains] = useState([""]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isOrg, setIsOrg] = useState(false);

  useEffect(() => {
    handleFetchOrganizations();
  }, []);

  const requestedSubdomain = validSubdomains.find(
    (lookingSubdomain) => lookingSubdomain === subdomain
  );

  const fullDomain = window.location.host;

  const appURL = process.env.REACT_APP_DOMAIN;

  const { pathname } = window.location;

  const handleFetchOrganizations: () => any = async () => {
    const organizations = await getOrganizations();
    const subdomains: string[] = [];
    organizations?.forEach((organization: Organization) => {
      subdomains.push(organization.name);
    });

    setValidSubdomains(subdomains);

    const domainArray = fullDomain.split(".");
    const appUrlArray = appURL?.split(".");

    let possibleOrgArray = domainArray.filter((x) => !appUrlArray?.includes(x));

    if (possibleOrgArray.length == 1) {
      setSubDomain(possibleOrgArray[0]);
    }

    setLoading(false);
  };

  if (
    !loading &&
    !isOrg &&
    fullDomain != appURL &&
    requestedSubdomain == undefined
  ) {
    return <NotFound />;
  }

  if (
    !loading &&
    !isOrg &&
    !token &&
    pathname !== "/validate" &&
    pathname !== "/register" &&
    pathname !== "/password/reset" &&
    pathname !== "/password/change"
  ) {
    return <Login />;
  }

  return (
    <div className="app">
      <Router>
        {((subdomain && requestedSubdomain) || subdomain === "") && !token && (
          <div>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Signup />} />
              <Route path="/password/reset" element={<ForgotPassword />} />
              <Route path="/password/change" element={<ChangePassword />} />
              <Route path="/validate" element={<ValidateEmail />} />
            </Routes>
          </div>
        )}
        {loading ? (
          <div className="ol-loading-spin">
            <Spin
              indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
              delay={500}
            />
          </div>
        ) : (
          subdomain &&
          requestedSubdomain &&
          token && (
            <div>
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Layout />}>
                  <Route path="/ia" element={<ChatIa />} />
                  <Route path="/admin">
                    <Route path="businessrules" element={<BusinessRules />} />
                    <Route path="goals" element={<Goals />} />
                    <Route path="parameters" element={<Parameters />} />
                    <Route path="stockmovements" element={<StockMovements />} />
                    <Route
                      path="stockmovements/create"
                      element={<NewStockMovement />}
                    />
                    <Route
                      path="parameters/seasons/create"
                      element={<NewSeason />}
                    />
                    <Route
                      path="parameters/seasons/:id/view"
                      element={<ViewSeason />}
                    />
                    <Route
                      path="parameters/commercialdates/create"
                      element={<NewCommercialDates />}
                    />
                    <Route
                      path="parameters/commercialdates/:id/view"
                      element={<ViewCommercialDate />}
                    />
                    {hasPermission(privilegesEnum.READ_CLUSTERS) && (
                      <>
                        <Route path="clusters" element={<Clusters />}></Route>
                        <Route
                          path="clusters/:id/view"
                          element={<ViewCluster />}
                        ></Route>
                      </>
                    )}
                    {hasPermission(privilegesEnum.WRITE_CLUSTERS) && (
                      <Route path="clusters/create" element={<NewCluster />} />
                    )}

                    {hasPermission(privilegesEnum.READ_VEHICLES) && (
                      <>
                        <Route path="vehicles" element={<Vehicles />}></Route>
                        <Route
                          path="vehicles/:id/view"
                          element={<ViewVehicle />}
                        ></Route>
                      </>
                    )}
                    {hasPermission(privilegesEnum.WRITE_VEHICLES) && (
                      <Route path="vehicles/create" element={<NewVehicle />} />
                    )}

                    {hasPermission(privilegesEnum.READ_CURVES) && (
                      <>
                        <Route path="curves" element={<Curves />}></Route>
                        <Route
                          path="curves/:id/view"
                          element={<ViewCurve />}
                        ></Route>
                      </>
                    )}
                    {hasPermission(privilegesEnum.WRITE_CURVES) && (
                      <Route path="curves/create" element={<NewCurve />} />
                    )}

                    {(hasPermission(privilegesEnum.READ_USERS) ||
                      hasPermission(privilegesEnum.READ_ROLES)) && (
                      <Route path="users" element={<UserRoles />} />
                    )}
                    {hasPermission(privilegesEnum.WRITE_USERS) && (
                      <Route path="users/create" element={<NewUser />} />
                    )}
                    {hasPermission(
                      privilegesEnum.READ_ACCEPTED_PAYMENT_METHODS
                    ) && (
                      <>
                        <Route
                          path="acceptedpaymentmethods"
                          element={<AcceptedPaymentMethods />}
                        />
                        <Route
                          path="acceptedpaymentmethods/:id/view"
                          element={<ViewAcceptedPaymentMethod />}
                        />
                      </>
                    )}
                    {hasPermission(
                      privilegesEnum.WRITE_ACCEPTED_PAYMENT_METHODS
                    ) && (
                      <Route
                        path="acceptedpaymentmethods/create"
                        element={<NewAcceptedPaymentMethods />}
                      />
                    )}
                    {hasPermission(privilegesEnum.READ_BRANDS) && (
                      <Route path="brands" element={<Brands />} />
                    )}
                    {hasPermission(privilegesEnum.WRITE_BRANDS) && (
                      <Route path="brands/create" element={<NewBrand />} />
                    )}
                    {hasPermission(privilegesEnum.READ_CATEGORIES) && (
                      <Route path="categories" element={<Categories />} />
                    )}
                    {hasPermission(privilegesEnum.WRITE_CATEGORIES) && (
                      <Route
                        path="categories/create"
                        element={<NewCategory />}
                      />
                    )}
                    {hasPermission(privilegesEnum.READ_CATEGORIES) && (
                      <Route
                        path="categories/:id/view"
                        element={<ViewCategory />}
                      />
                    )}
                    {hasPermission(privilegesEnum.READ_DEPOSITS) && (
                      <>
                        <Route path="deposits" element={<Deposits />} />
                        <Route
                          path="deposits/:id/view"
                          element={<ViewDeposit />}
                        />
                      </>
                    )}
                    {hasPermission(privilegesEnum.READ_COLOURS) && (
                      <>
                        <Route path="colours" element={<Colours />} />
                      </>
                    )}
                    {hasPermission(privilegesEnum.WRITE_COLOURS) && (
                      <Route path="colours/create" element={<NewColour />} />
                    )}
                    {hasPermission(privilegesEnum.WRITE_DEPOSITS) && (
                      <Route path="deposits/create" element={<NewDeposit />} />
                    )}
                    {hasPermission(privilegesEnum.READ_BRANCH_OFFICES) && (
                      <>
                        <Route
                          path="branchoffices"
                          element={<BranchOffices />}
                        />
                        <Route
                          path="branchoffices/create"
                          element={<NewBranchOffice />}
                        />
                        <Route
                          path="branchoffices/:id/view"
                          element={<ViewBranchOffice />}
                        />
                      </>
                    )}
                    {hasPermission(privilegesEnum.READ_ATTRIBUTES) && (
                      <Route path="attributes" element={<Attributes />} />
                    )}
                    {hasPermission(privilegesEnum.WRITE_ATTRIBUTES) && (
                      <Route
                        path="attributes/create"
                        element={<NewAttribute />}
                      />
                    )}
                    {hasPermission(privilegesEnum.READ_PRICING_TYPES) && (
                      <Route path="pricingtypes" element={<PricingTypes />} />
                    )}
                    <Route path="promotiontypes" element={<PromotionTypes />} />
                    {hasPermission(privilegesEnum.READ_ARTICLES) && (
                      <>
                        <Route path="articles" element={<Articles />} />
                        <Route
                          path="articles/create"
                          element={<NewArticle />}
                        />
                        <Route
                          path="articles/:id/view"
                          element={<ViewArticle />}
                        />
                      </>
                    )}
                    {hasPermission(privilegesEnum.READ_SUPPLIERS) && (
                      <>
                        <Route path="suppliers" element={<Suppliers />} />
                        <Route
                          path="suppliers/create"
                          element={<NewSupplier />}
                        />
                        <Route
                          path="suppliers/:id/view"
                          element={<ViewSupplier />}
                        />
                      </>
                    )}
                    {hasPermission(privilegesEnum.READ_SUPPLIERS) && (
                      <Route
                        path="suppliers/payment/create"
                        element={<NewSupplierPaymentMethod />}
                      />
                    )}
                    {hasPermission(privilegesEnum.READ_BUSINESS_UNITS) && (
                      <Route
                        path="businessunit/:id/view"
                        element={<ViewBusinessUnit />}
                      />
                    )}
                  </Route>
                  <Route
                    path="/fidelity/recommendations"
                    element={<RecommendationsMockup />}
                  />
                  <Route
                    path="/fidelity/rewardtypes"
                    element={<RewardTypes />}
                  />
                  <Route
                    path="/fidelity/rewardtypes/create"
                    element={<NewRewardType />}
                  />
                  <Route
                    path="/fidelity/rewardtypes/:id/view"
                    element={<ViewRewardType />}
                  />
                  <Route
                    path="/fidelity/rewards/:id/view"
                    element={<ViewReward />}
                  />

                  <Route path="/fidelity/units" element={<UnitsMockup />} />
                  <Route path="/fidelity/rewards" element={<Rewards />} />
                  <Route
                    path="/fidelity/rewards/create"
                    element={<NewReward />}
                  />
                  {hasPermission(privilegesEnum.READ_CLIENT_LEVEL) && (
                    <>
                      <Route
                        path="/promotions/clientlevels"
                        element={<ClientLevels />}
                      ></Route>
                      <Route
                        path="/promotions/clientlevels/:id/view"
                        element={<ViewClientLevel />}
                      ></Route>
                    </>
                  )}
                  {hasPermission(privilegesEnum.WRITE_VEHICLES) && (
                    <Route
                      path="/promotions/clientlevels/create"
                      element={<NewClientLevel />}
                    />
                  )}
                  {hasPermission(privilegesEnum.READ_CLIENTS) && (
                    <>
                      <Route path="/promotions/clients" element={<Clients />} />
                      <Route path="/fidelity/clients" element={<Clients />} />
                      {hasPermission(privilegesEnum.READ_TICKETS) && (
                        <>
                          <Route
                            path="/promotions/tickets"
                            element={<Tickets />}
                          />
                          <Route
                            path="/promotions/ticketsold"
                            element={<TicketsOld />}
                          />
                          <Route
                            path="/fidelity/tickets"
                            element={<Tickets />}
                          />
                          {/*<Route path="/tickets/create" element={<NewTicket />} />*/}
                          <Route
                            path="/promotions/tickets/:id/view"
                            element={<ViewTicket />}
                          />
                        </>
                      )}
                      <Route
                        path="/promotions/clients/create"
                        element={<NewClient />}
                      />
                      <Route
                        path="/fidelity/clients/create"
                        element={<NewClient />}
                      />
                      <Route
                        path="/promotions/clients/:id/view"
                        element={<ViewClient />}
                      />
                      <Route
                        path="/promotions/clientsegments"
                        element={<ClientSegments />}
                      />

                      <Route
                        path="/fidelity/clientsegments"
                        element={<ClientSegments />}
                      />
                      <Route
                        path="/promotions/clientsegments/create"
                        element={<NewClientSegment />}
                      />

                      <Route
                        path="/fidelity/clientsegments/create"
                        element={<NewClientSegment />}
                      />

                      <Route
                        path="/promotions/clientsegments/:id/view"
                        element={<ViewClientSegment />}
                      />
                    </>
                  )}
                  {hasPermission(privilegesEnum.READ_PRICINGS) && (
                    <>
                      <Route path="/pricings" element={<Pricings />} />
                      <Route
                        path="/pricings/:id/view"
                        element={<ViewPricing />}
                      />
                      <Route
                        path="/pricings/calendar"
                        element={<CalendarPricings />}
                      />
                      {hasPermission(privilegesEnum.READ_ARTICLE_GROUP) && (
                        <Route
                          path="/pricings/articlegroups"
                          element={<ArticleGroups />}
                        />
                      )}
                      <Route
                        path="/pricings/articlegroups/create"
                        element={<NewArticleGroup />}
                      />
                      <Route
                        path="/pricings/articlegroups/:id/view"
                        element={<ViewArticleGroup />}
                      />
                      <Route path="/pricings/create" element={<NewPricing />} />
                    </>
                  )}
                  {hasPermission(privilegesEnum.READ_PROMOTIONS) && (
                    <>
                      <Route
                        path="/promotions/calendar"
                        element={<CalendarPromotions />}
                      />
                      <Route
                        path="/promotions"
                        element={<PromotionsMockUp />}
                      />
                      <Route
                        path="/promotions/:id/view"
                        element={<ViewPromotion />}
                      />
                      <Route
                        path="/promotions/create"
                        element={<NewPromotion />}
                      />
                    </>
                  )}

                  {hasPermission(privilegesEnum.READ_ARTICLE_GROUP) && (
                    <Route
                      path="/promotions/articlegroups"
                      element={<ArticleGroups />}
                    />
                  )}
                  {hasPermission(privilegesEnum.READ_PURCHASE_ORDERS) && (
                    <>
                      <Route path="/purchaseorders">
                        <Route
                          path="dashboard"
                          element={<PurchaseOrderDashboard />}
                        />
                        <Route path="management" element={<PurchaseOrders />} />
                        <Route path="create" element={<NewPurchaseOrder2 />} />
                        <Route
                          path=":id/view"
                          element={<ViewPurchaseOrder />}
                        />
                        {/*<Route path="reports" />*/}
                        <Route
                          path="stockbreaks"
                          element={<StockBreaksPurchaseOrders />}
                        />
                        <Route
                          path="catalogue"
                          element={<CataloguePurchaseOrder />}
                        />
                        <Route path="requests" />
                      </Route>
                    </>
                  )}
                  {hasPermission(privilegesEnum.READ_REPOSITION_ORDERS) && (
                    <>
                      <Route path="/repositionorders">
                        <Route
                          path="dashboard"
                          element={<RepositionOrderDashboard />}
                        />
                        {/*<Route path="reports" />*/}
                        <Route
                          path="management"
                          element={<RepositionOrders />}
                        />
                        <Route path="stockbreaks" element={<StockBreaks />} />
                        <Route path="catalogue" element={<Catalogue />} />
                        <Route path="requests" element={<Requests />} />
                        <Route
                          path="requests/:id/detail"
                          element={<RequestDetail />}
                        />
                        <Route path="create" element={<NewRepositionOrder />} />
                        <Route
                          path=":id/view"
                          element={<ViewRepositionOrder />}
                        />
                      </Route>
                    </>
                  )}
                  <Route path="/" element={<Home />} />
                </Route>

                {/*<Route path="/register" element={<Signup />}></Route>*/}
              </Routes>
            </div>
          )
        )}
      </Router>
    </div>
  );
}
export default App;
