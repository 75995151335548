import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  getArticlesFiltered,
  getBranchOfficesFiltered,
  getDepositsFiltered,
  getMovementStockTypes,
  getStockMovements,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Table, Button, Select, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { BusinessUniteContext } from "../../components/laoyut/Layout";
import styles from "./StockMovements.module.scss";

const StockMovements = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [depositOptions, setDepositOptions] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [loadingDeposits, setLoadingDeposits] = useState(false);
  const [articleOptions, setArticleOptions] = useState<any[]>([]);
  const [articleSelected, setArticleSelected] = useState(undefined);
  const [typeOptions, setTypeOptions] = useState<any[]>([]);
  const [typeSelected, setTypeSelected] = useState(undefined);

  const [branchOfficeIdFilterValue, setBranchOfficeIdFilterValue] =
    useState("");
  const [depositIdFilterValue, setDepositIdFilterValue] = useState("");
  const [branchOffices, setBranchOffices] = useState<any[]>([]);

  const [actualFilterStartCreatedDate, setActualFilterStartCreatedDate] =
    useState<any>(null);
  const [actualFilterEndCreatedDate, setActualFilterEndCreatedDate] =
    useState<any>(null);
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  useEffect(() => {
    handleSearchArticles("");
    handleFetchMovementTypes();
    handleFetchMovements();
    handleSearchBranchOfficesFilter("");
    handleSearchDeposits("");
  }, [businessUnitSelected]);

  const handleSearchDeposits = async (value: string) => {
    setLoadingDeposits(true);
    const response = await getDepositsFiltered(0, {
      name: value,
    });
    const options = response.deposits.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setDepositOptions(options);
    setLoadingDeposits(false);
  };

  const handleFetchMovements = async (
    pagination = actualPage,
    filters?: {
      startDate?: string | null;
      endDate?: string | null;
      articleId?: string | null;
      type?: string | null;
      branchOfficeId?: string;
      depositId?: string;
    }
  ) => {
    if (businessUnitSelected) {
      const response = await getStockMovements({
        pagination,
        businessUnitId: businessUnitSelected,
        ...filters,
      });
      setData(response.stockMovements);
      setTotalSize(response.totalElements);
      setLoading(false);
    }
  };

  const handlePagination = (page: number) => {
    handleFetchMovements(page);
    setActualPage(page);
  };

  const handleSearchArticles = async (value: string) => {
    setLoadingArticles(true);
    const response = await getArticlesFiltered(0, { articleName: value }, true);
    const options = response.articles.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setArticleOptions(options);
    setLoadingArticles(false);
  };

  const handleFetchMovementTypes = async () => {
    const response = await getMovementStockTypes();
    const options = response
      .filter((item: any) => item.value !== "ADJUSTMENT_EXCEL")
      .map((item: any) => ({
        value: item.value,
        text: item.description_es,
      }));
    setTypeOptions(options);
  };

  const handleChangeDateFilter = (value: any) => {
    setActualFilterStartCreatedDate(
      value ? moment(value[0], "YYYY-MM-DD").format("YYYY-MM-DD") ?? null : null
    );
    setActualFilterEndCreatedDate(
      value ? moment(value[1], "YYYY-MM-DD").format("YYYY-MM-DD") ?? null : null
    );
    setActualPage(0);
    handleFetchMovements(0, {
      startDate: value
        ? value[0]
          ? moment(value[0], "YYYY-MM-DD").format("YYYY-MM-DD") ?? null
          : null
        : null,
      endDate: value
        ? value[1]
          ? moment(value[1], "YYYY-MM-DD").format("YYYY-MM-DD") ?? null
          : null
        : null,
      articleId: articleSelected,
      type: typeSelected,
      branchOfficeId: branchOfficeIdFilterValue,
      depositId: depositIdFilterValue,
    });
  };

  const handleSearchBranchOfficesFilter = async (value: string) => {
    if (businessUnitSelected) {
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const columns = [
    {
      title: t("Common-Article"),
      key: "article",
      render: (data: any) => {
        if (data.skuDeposit) {
          return (
            <a href={`/admin/articles/${data.skuDeposit.sku.articleId}/view`}>
              {data.skuDeposit.sku.articleName}
            </a>
          );
        }
        if (data.skuBranchOffice) {
          return (
            <a
              href={`/admin/articles/${data.skuBranchOffice.sku.articleId}/view`}
            >
              {data.skuBranchOffice.sku.articleName}
            </a>
          );
        }
      },
    },
    {
      title: t("Common-Sku"),
      key: "sku",
      render: (data: any) => {
        if (data.skuDeposit) {
          return data.skuDeposit.sku.name;
        }
        if (data.skuBranchOffice) {
          return data.skuBranchOffice.sku.name;
        }
      },
    },
    {
      title: t("Common-Place"),
      key: "place",
      render: (data: any) => {
        if (data.skuDeposit) {
          return (
            <a href={`/admin/deposits/${data.skuDeposit.deposit.id}/view`}>
              {data.skuDeposit.deposit.name}
            </a>
          );
        }
        if (data.skuBranchOffice) {
          return (
            <a
              href={`/admin/branchoffices/${data.skuBranchOffice.branchOffice.id}/view`}
            >
              {data.skuBranchOffice.branchOffice.name}
            </a>
          );
        }
      },
    },
    {
      title: t("Common-Type"),
      dataIndex: "stockMovementType",
      render: (type: {
        description_en: string;
        description_es: string;
        value: string;
      }) => type.description_es,
    },
    {
      title: t("Common-Date"),
      dataIndex: "date",
      render: (date: string) => date.replace("T", " "),
    },
    {
      title: t("Common-Stock"),
      dataIndex: "stock",
    },
    {
      title: t("Common-Motive"),
      dataIndex: "reason",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-StockMovements")}</h1>
        {/*{hasPermission(privilegesEnum.WRITE_COMPETITORS) && (*/}
        <div className={styles.actionButtonsWrapper}>
          {hasPermission(privilegesEnum.WRITE_VEHICLES) && (
            <div className={styles.actionButtonsWrapper}>
              <Button
                onClick={() => {
                  navigate("/admin/stockmovements/create");
                }}
                className={styles.newButton}
                type="primary"
                icon={<PlusOutlined />}
              />
            </div>
          )}
        </div>
        {/*)}*/}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <DatePicker.RangePicker
                  className={styles.dateSelectorFilter}
                  format="YYYY-MM-DD "
                  allowEmpty={[true, true]}
                  onChange={handleChangeDateFilter}
                  placeholder={[t("Common-Start"), t("Common-End")]}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  value={articleSelected}
                  allowClear
                  onClear={() => {
                    setArticleSelected(undefined);
                    handleFetchMovements(0, {
                      startDate: actualFilterStartCreatedDate,
                      endDate: actualFilterEndCreatedDate,
                      articleId: undefined,
                      type: typeSelected,
                      branchOfficeId: branchOfficeIdFilterValue,
                      depositId: depositIdFilterValue,
                    });
                  }}
                  placeholder={t("Common-SearchArticleTextPlaceholder")}
                  onSelect={(_, option) => {
                    if (option) {
                      setArticleSelected(option.value);
                      handleFetchMovements(0, {
                        startDate: actualFilterStartCreatedDate,
                        endDate: actualFilterEndCreatedDate,
                        articleId: option.value,
                        type: typeSelected,
                        branchOfficeId: branchOfficeIdFilterValue,
                        depositId: depositIdFilterValue,
                      });
                    }
                  }}
                  loading={loadingArticles}
                  className={styles.filterSelect}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchArticles}
                  options={(articleOptions || []).map((d: any) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  value={typeSelected}
                  allowClear
                  onClear={() => {
                    setTypeSelected(undefined);
                    handleFetchMovements(0, {
                      startDate: actualFilterStartCreatedDate,
                      endDate: actualFilterEndCreatedDate,
                      articleId: articleSelected,
                      type: undefined,
                      branchOfficeId: branchOfficeIdFilterValue,
                      depositId: depositIdFilterValue,
                    });
                  }}
                  placeholder={t("Common-SearchByType")}
                  onSelect={(_, option) => {
                    if (option) {
                      setTypeSelected(option.value);
                      handleFetchMovements(0, {
                        startDate: actualFilterStartCreatedDate,
                        endDate: actualFilterEndCreatedDate,
                        articleId: articleSelected,
                        type: option.value,
                        branchOfficeId: branchOfficeIdFilterValue,
                        depositId: depositIdFilterValue,
                      });
                    }
                  }}
                  className={styles.filterSelect}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchArticles}
                  options={(typeOptions || []).map((d: any) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                />
              </div>
            </div>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Select
                  allowClear
                  className={styles.filterSelect}
                  showSearch
                  onSearch={handleSearchBranchOfficesFilter}
                  placeholder={t("Common-SearchByBranchOffice")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(_, option) => {
                    if (option) {
                      setBranchOfficeIdFilterValue(option.value);
                      handleFetchMovements(0, {
                        startDate: actualFilterStartCreatedDate,
                        endDate: actualFilterEndCreatedDate,
                        articleId: articleSelected,
                        type: typeSelected,
                        branchOfficeId: option.value,
                        depositId: depositIdFilterValue,
                      });
                    }
                  }}
                  onClear={() => {
                    setBranchOfficeIdFilterValue("");
                    handleFetchMovements(0, {
                      startDate: actualFilterStartCreatedDate,
                      endDate: actualFilterEndCreatedDate,
                      articleId: articleSelected,
                      type: typeSelected,
                      branchOfficeId: undefined,
                      depositId: depositIdFilterValue,
                    });
                  }}
                >
                  {getOptions(branchOffices)}
                </Select>
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  allowClear
                  onClear={() => {
                    setTypeSelected(undefined);
                    handleFetchMovements(0, {
                      startDate: actualFilterStartCreatedDate,
                      endDate: actualFilterEndCreatedDate,
                      articleId: articleSelected,
                      type: typeSelected,
                      depositId: undefined,
                      branchOfficeId: branchOfficeIdFilterValue,
                    });
                  }}
                  placeholder={t("Common-SearchDepositTextPlaceholder")}
                  onSelect={(_, option) => {
                    if (option) {
                      setDepositIdFilterValue(option.value);
                      handleFetchMovements(0, {
                        startDate: actualFilterStartCreatedDate,
                        endDate: actualFilterEndCreatedDate,
                        articleId: articleSelected,
                        type: typeSelected,
                        depositId: option.value,
                        branchOfficeId: branchOfficeIdFilterValue,
                      });
                    }
                  }}
                  className={styles.filterSelect}
                  onSearch={handleSearchDeposits}
                  options={(depositOptions || []).map((d: any) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                />
              </div>
            </div>
          </div>
          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StockMovements;
