import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  createStockMovements,
  getBranchOfficesFiltered,
  getBranchOfficeSkusById,
  getDepositsFiltered,
  getDepositsSkusById,
  getMovementStockTypes,
} from "../../../api/Services";

import {
  Form,
  Input,
  Button,
  message,
  Select,
  Spin,
  InputNumber,
  Radio,
  DatePicker,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./NewStockMovement.module.scss";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";

enum PlaceValue {
  branchoffice,
  deposit,
}
const NewStockMovement = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [loadingBranchOffices, setLoadingBranchOffices] = useState(false);
  const [loadingDeposits, setLoadingDeposits] = useState(false);
  const [actualBranchOfficeId, setActualBranchOfficeId] = useState<any>(null);
  const [branchOfficeOptions, setBranchOfficeOptions] = useState([]);
  const [actualDepositId, setActualDepositId] = useState<any>(null);
  const [depositOptions, setDepositOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState<any[]>([]);
  const [skuOptions, setSkuOptions] = useState([]);
  const [placeValue, setPlaceValue] = useState<PlaceValue>(
    PlaceValue.branchoffice
  );
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);
  const [form] = Form.useForm();

  useEffect(() => {
    handleFetchMovementTypes();
    handleSearchDeposits("");
  }, [businessUnitSelected]);

  const handleFetchSkusBranchOffice = async (id: string, skuName: string) => {
    const response = await getBranchOfficeSkusById(id, 0, skuName);
    const options = response.skus?.map((item: any) => ({
      value: item.id,
      text: item.sku.name,
    }));
    setSkuOptions(options);
  };

  const handleFetchSkusDeposits = async (id: string, skuName: string) => {
    const response = await getDepositsSkusById(id, 0, skuName);
    const options = response.skus?.map((item: any) => ({
      value: item.id,
      text: item.sku.name,
    }));
    setSkuOptions(options);
  };

  const handleFetchMovementTypes = async () => {
    const response = await getMovementStockTypes();
    const options = response
      .filter((item: any) => item.value !== "ADJUSTMENT_EXCEL")
      .map((item: any) => ({
        value: item.value,
        text: item.description_es,
      }));
    setTypeOptions(options);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (businessUnitSelected) {
      setLoadingBranchOffices(true);
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      const options = response.branchOffices.map((item: any) => ({
        value: item.id,
        text: item.name,
      }));
      setBranchOfficeOptions(options);
      setLoadingBranchOffices(false);
    } else setBranchOfficeOptions([]);
  };

  const handleSearchDeposits = async (value: string) => {
    setLoadingDeposits(true);
    const response = await getDepositsFiltered(0, {
      name: value,
    });
    const options = response.deposits.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setDepositOptions(options);
    setLoadingDeposits(false);
  };

  const handleAddStock = async () => {
    try {
      await form.validateFields();
      setLoading(true);
      const response = await createStockMovements({
        stock: form.getFieldValue("stock"),
        type: form.getFieldValue("type"),
        skuBranchOfficeId: form.getFieldValue("sku"),
        date: form.getFieldValue("date").format("YYYY-MM-DDTHH:mm:ss"),
        reason: form.getFieldValue("reason"),
      });

      if (response) {
        message.success(t("Common-CreateSuccessMessage"));
        form.resetFields();
        navigate("/admin/stockmovements");
      } else message.error(t("Common-ErrorMessage"));
    } catch (error) {
    } finally {
      setLoading(false);
    }

    setLoading(false);
  };
  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-AddStockMovement")}</h1>
        <Button
          onClick={handleAddStock}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="addStockMovement"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.formNew}
            form={form}
            onFinish={handleAddStock}
          >
            <Form.Item
              className={styles.newItem}
              label={t("Common-Type")}
              name="type"
              rules={[
                {
                  required: true,
                  message: t("Common-Type"),
                },
              ]}
            >
              <Select
                options={(typeOptions || []).map((d: any) => ({
                  value: d.value,
                  label: d.text,
                }))}
                placeholder={t("Common-Type")}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("Common-Place"),
                },
              ]}
              label={t("Common-Place")}
            >
              <Radio.Group
                value={placeValue}
                onChange={(e) => {
                  setPlaceValue(e.target.value);
                  setSkuOptions([]);
                }}
              >
                <Radio value={PlaceValue.branchoffice}>
                  {t("Common-BranchOffice")}
                </Radio>
                <Radio value={PlaceValue.deposit}>{t("Common-Deposit")}</Radio>
              </Radio.Group>
            </Form.Item>
            {placeValue === PlaceValue.branchoffice && (
              <Form.Item
                className={styles.newItem}
                label={t("Common-BranchOffice")}
                name="branchoffice"
                rules={[
                  {
                    required: true,
                    message: t("Common-BranchOffice"),
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={t("Common-SearchBranchOfficeTextPlaceholder")}
                  onSelect={(_, option) => {
                    if (option) {
                      setActualBranchOfficeId(option.value);
                      setSkuOptions([]);
                    }
                  }}
                  notFoundContent={
                    loadingBranchOffices ? <Spin size="small" /> : null
                  }
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchBranchOffices}
                  options={(branchOfficeOptions || []).map((d: any) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                />
              </Form.Item>
            )}
            {placeValue === PlaceValue.deposit && (
              <Form.Item
                className={styles.newItem}
                label={t("Common-Deposit")}
                name="deposit"
                rules={[
                  {
                    required: true,
                    message: t("Common-Deposit"),
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={t("Common-SearchDepositTextPlaceholder")}
                  onSelect={(_, option) => {
                    if (option) {
                      setActualDepositId(option.value);
                      setSkuOptions([]);
                    }
                  }}
                  notFoundContent={
                    loadingDeposits ? <Spin size="small" /> : null
                  }
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchDeposits}
                  options={(depositOptions || []).map((d: any) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                />
              </Form.Item>
            )}
            <Form.Item
              className={styles.newItem}
              label={t("Common-Sku")}
              name="sku"
              rules={[
                {
                  required: true,
                  message: t("Common-Sku"),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t("Common-SearchBySkuName")}
                notFoundContent={
                  loadingBranchOffices ? <Spin size="small" /> : null
                }
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) =>
                  placeValue === PlaceValue.branchoffice
                    ? handleFetchSkusBranchOffice(actualBranchOfficeId, value)
                    : handleFetchSkusDeposits(actualDepositId, value)
                }
                options={(skuOptions || []).map((d: any) => ({
                  value: d.value,
                  label: d.text,
                }))}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Stock")}
              name="stock"
              rules={[
                {
                  required: true,
                  message: t("Common-Stock"),
                },
              ]}
            >
              <InputNumber
                className={styles.numberInput}
                placeholder={t("Common-Stock")}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Date")}
              name="date"
              rules={[
                {
                  required: true,
                  message: t("Common-Date"),
                },
              ]}
            >
              <DatePicker
                className={styles.dateSelector}
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Motive")}
              name="reason"
            >
              <Input placeholder={t("Common-Motive")} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewStockMovement;
