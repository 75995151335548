import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  createSupplierPaymentMethod,
  getAcceptedPaymentMethodsTypes,
} from "../../../api/Services";

import { Form, Input, Button, message, Select, Spin, InputNumber } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./NewSupplierPaymentMethod.module.scss";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
const NewSupplierPaymentMethod = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [types, setTypes] = useState<any[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_SUPPLIER_PAYMENT_METHODS)) {
      handleFetchAcceptedPaymentMethodsTypes();
    }
  }, []);

  const handleFetchAcceptedPaymentMethodsTypes = async () => {
    const response = await getAcceptedPaymentMethodsTypes();
    setTypes(response);
  };

  const getAcceptedPaymentMethodsTypesOptions = () => {
    const options: any[] = [];

    types.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };

  const handleCreateNewSupplierMethod = async () => {
    try {
      await form.validateFields();
      setLoading(true);
      const response = await createSupplierPaymentMethod({
        name: form.getFieldValue("name"),
        paymentDeadlineDays: form.getFieldValue("paymentDeadline"),
        typeId: form.getFieldValue("type"),
      });

      if (response) {
        message.success(t("Common-CreateSuccessMessage"));
        form.resetFields();
        navigate(`/admin/suppliers/`);
      } else {
        message.error(t("Common-ErrorMessage"));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewSupplierPaymentMethod")}</h1>
        <Button
          onClick={handleCreateNewSupplierMethod}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="new"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.formNew}
            form={form}
            onFinish={handleCreateNewSupplierMethod}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>

            <Form.Item
              label={t("NewSupplierPaymentMethod-PaymentDeadline")}
              name="paymentDeadline"
              rules={[
                {
                  required: true,
                  message: t("NewSupplierPaymentMethod-PaymentDeadlineError"),
                },
              ]}
            >
              <InputNumber
                placeholder={t("NewSupplierPaymentMethod-PaymentDeadline")}
                addonAfter={t("Common-Days")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item label={t("Common-Type")} name="type">
              <Select
                className={styles.type}
                allowClear
                style={{ width: "100%" }}
                placeholder=""
              >
                {getAcceptedPaymentMethodsTypesOptions()}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewSupplierPaymentMethod;
