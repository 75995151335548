import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { createAttribute } from "../../../api/Services";

import { Form, Input, Button, message, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./NewAttribute.module.scss";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";
const NewAttribute = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCreateAttribute = async () => {
    try {
      await form.validateFields();
      setLoading(true);
      const response = await createAttribute(
        form.getFieldValue("name"),
        businessUnitSelected,
        form.getFieldValue("enums")
      );

      if (response) {
        message.success(t("Common-CreateSuccessMessage"));
        form.resetFields();
        navigate("/admin/attributes");
      } else message.error(t("Common-CreateErrorMessage"));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewAttribute")}</h1>
        <Button
          onClick={handleCreateAttribute}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newAttribute"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.formNew}
            form={form}
            onFinish={handleCreateAttribute}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>

            <Form.Item label={t("Common-PossibleValues")} name="enums">
              <Select
                allowClear
                placeholder={t("Common-EnterPossibleValues")}
                showSearch
                mode="tags"
                className={styles.selector}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewAttribute;
