import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  createSupplier,
  getAllSuppliersPaymentMethods,
  getArticlesFiltered,
  getPurchaseUnitsFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { Form, message, Space, Select, Button, Input, Switch } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

import styles from "./NewSupplier.module.scss";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

const NewSupplier = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState<any[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [paymentMethodsSelected, setPaymentMethodsSelected] = useState<any>([]);
  const [purchaseUnits, setPurchaseUnits] = useState<any[]>([]);

  const [newSupplierForm] = Form.useForm();
  const [contactsForm] = Form.useForm();
  const [articlesForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.WRITE_SUPPLIERS)) {
      handleFetchPaymentMethods();
    }
  }, []);

  const handleFetchArticles = async (nameFilterValue: string) => {
    const response = await getArticlesFiltered(0, {
      articleName: nameFilterValue,
    });
    setArticles(response.articles);
  };

  const handleSearchPurchaseUnits = async (value: string) => {
    if (value.length > 2) {
      const response = await getPurchaseUnitsFiltered(value);
      setPurchaseUnits(response);
    } else setPurchaseUnits([]);
  };

  const handleFetchPaymentMethods = async () => {
    const response = await getAllSuppliersPaymentMethods();
    setPaymentMethods(response);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getPaymentOptionsById = (ids: number[]) => {
    const options: any[] = [];
    paymentMethods?.forEach(function (item: any) {
      if (ids.includes(item.id)) {
        options.push(
          <Select.Option value={item.id} key={item.id}>
            {item.name}
          </Select.Option>
        );
      }
    });

    return options;
  };

  const getOptionsPurchaseUnit = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.purchaseUnit}
        </Select.Option>
      );
    });

    return options;
  };

  const handleValidateSuppliersFields = async () => {
    await newSupplierForm.validateFields().then((supplier) => {
      contactsForm.validateFields().then((contacts) => {
        articlesForm.validateFields().then((articles) => {
          if (businessUnitSelected)
            supplier.businessUnitsIds = [businessUnitSelected];
          handleCreateSupplier(supplier, contacts.contacts, articles.articles);
        });
      });
    });
  };

  const handleCreateSupplier = async (
    supplier: any,
    contacts: any,
    articles: any
  ) => {
    await newSupplierForm.validateFields();
    await contactsForm.validateFields();
    await articlesForm.validateFields();

    setLoading(true);
    const {
      name,
      externalId,
      website,
      catalogueLink,
      businessUnitsIds,
      supplierPaymentMethodsIds,
    } = supplier;

    let articlesApi;

    if (articles) {
      articlesApi = articles.map((article: any) => {
        return {
          articleId: article.articleId,
          principal: article.principal,
          commercialTerm: {
            paymentMethod: article.paymentMethod,
            deliveryTerm: article.deliveryTerm,
            minimumOrder: article.minimumOrder,
            cost: parseInt(article.cost),
            agreedDiscounts: parseInt(article.agreedDiscounts),
            supplierPaymentMethodId: article.paymentMethod,
          },
          purchaseUnitId: article.purchaseUnit,
        };
      });
    }

    const response = await createSupplier(
      name,
      externalId,
      website,
      catalogueLink,
      businessUnitsIds,
      supplierPaymentMethodsIds,
      contacts,
      articlesApi
    );

    if (response.success) {
      message.success(t("NewSupplier-CreateSupplierSucessMessage"));
      newSupplierForm.resetFields();
      contactsForm.resetFields();
      articlesForm.resetFields();
      navigate(`/admin/suppliers/${response.supplier.id}/view`);
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("NewSupplier-NewSupplier")}</h1>
        <Button
          onClick={handleValidateSuppliersFields}
          className={styles.saveButton}
          loading={loading}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.supplierInfoWrapper}>
          <Form
            name="newSupplierForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={newSupplierForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item label={t("Common-ExternalId")} name="externalId">
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>
            <Form.Item
              label={t("Common-AcceptedPaymentMethods")}
              name="supplierPaymentMethodsIds"
            >
              <Select
                allowClear
                placeholder={t("Common-AcceptedPaymentMethods")}
                showSearch
                mode="multiple"
                onChange={setPaymentMethodsSelected}
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptions(paymentMethods)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-Website")}
              name="website"
              rules={[
                {
                  type: "url",
                  message: t("Common-UrlNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-exampleURL")} />
            </Form.Item>

            <Form.Item
              label={t("Suppliers-CatalogueLink")}
              name="catalogueLink"
              rules={[
                {
                  type: "url",
                  message: t("Common-UrlNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-exampleURL")} />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.contactWrapper}>
          <h1 className={styles.contactsTitle}>{t("NewSupplier-Contacts")}</h1>
          <div className={styles.contactsFormWrapper}>
            <Form
              name="contacts"
              initialValues={{ remember: true }}
              autoComplete="off"
              className={styles.contactForm}
              form={contactsForm}
            >
              <Form.List name="contacts">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        className={styles.contactSpace}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          label={t("NewSupplier-ContactName")}
                          className={styles.contactInfoItem}
                          name={[name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: t("NewSupplier-ContactNameNotEntered"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.contactInfoItem}
                            placeholder={t("NewSupplier-ContactName")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("Common-Rol")}
                          className={styles.contactInfoItem}
                          name={[name, "role"]}
                        >
                          <Input
                            className={styles.contactInfoItem}
                            placeholder={t("Common-Rol")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("NewSupplier-ContactNumber")}
                          className={styles.contactInfoItem}
                          name={[name, "phoneNumber"]}
                        >
                          <Input
                            className={styles.contactInfoItem}
                            placeholder={t("NewSupplier-ContactNumber")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("NewSupplier-ContactEmail")}
                          className={styles.contactInfoItem}
                          name={[name, "email"]}
                        >
                          <Input
                            className={styles.contactInfoItem}
                            placeholder={t("NewSupplier-ContactEmail")}
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        className={styles.actionsButton}
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        </div>
        <div className={styles.articlesWrapper}>
          <h1 className={styles.articlesTitle}>{t("Common-Articles")}</h1>
          <div className={styles.articlesFormWrapper}>
            <Form
              name="articles"
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              className={styles.articleForm}
              form={articlesForm}
            >
              <Form.List name="articles">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        className={styles.contactSpace}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          label={t("Common-Article")}
                          className={styles.articleInfoItem}
                          name={[name, "articleId"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-Article"),
                            },
                          ]}
                        >
                          <Select
                            className={styles.articleInfoItem}
                            allowClear
                            placeholder={t("Common-SearchByName")}
                            filterOption={(input: string, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            style={{ width: "100%" }}
                            onSearch={(value) => handleFetchArticles(value)}
                          >
                            {getOptions(articles)}
                          </Select>
                        </Form.Item>
                        {/* TODO: Cambiar esto?? */}
                        <Form.Item
                          {...restField}
                          label={t("NewSupplier-PaymentMethod")}
                          className={styles.articleInfoItem}
                          name={[name, "paymentMethod"]}
                        >
                          <Select
                            allowClear
                            placeholder={t("NewSupplier-PaymentMethod")}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input: string, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {getPaymentOptionsById(paymentMethodsSelected)}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label={t("NewSupplier-DeliveryTerm")}
                          className={styles.articleInfoItem}
                          name={[name, "deliveryTerm"]}
                        >
                          <Input
                            className={styles.articleInfoItem}
                            placeholder={t("NewSupplier-DeliveryTerm")}
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label={t("NewSupplier-MinimumOrder")}
                          className={styles.articleInfoItem}
                          name={[name, "minimumOrder"]}
                          rules={[
                            {
                              required: true,
                              message: t("NewSupplier-MinimumOrder"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.articleInfoItem}
                            placeholder={t("NewSupplier-MinimumOrder")}
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label={t("NewSupplier-PurchaseUnit")}
                          className={styles.articleInfoItem}
                          name={[name, "purchaseUnit"]}
                        >
                          <Select
                            allowClear
                            placeholder={t("Common-SearchByName")}
                            onSearch={handleSearchPurchaseUnits}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input: string, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {getOptionsPurchaseUnit(purchaseUnits)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("Common-Cost")}
                          className={styles.articleInfoItem}
                          name={[name, "cost"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-Cost"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.articleInfoItem}
                            placeholder={t("Common-Cost")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("Common-AgreedDiscounts")}
                          className={styles.articleInfoItem}
                          name={[name, "agreedDiscounts"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-AgreedDiscounts"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.articleInfoItem}
                            placeholder={t("Common-AgreedDiscounts")}
                          />
                        </Form.Item>
                        <Form.Item
                          initialValue={false}
                          label={t("NewSupplier-Principal")}
                          {...restField}
                          name={[name, "principal"]}
                        >
                          <Switch />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        className={styles.actionsButton}
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSupplier;
